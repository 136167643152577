import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
        <section className="o-section ts:lead u-mb:-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 col-xl-7 mx-auto u-ta:c ts:lead u-color:dark-theme">
                        <h1>Oups !</h1>
                        <p>Cette page n'existe pas !</p>
                    </div>
                </div>
            </div>
        </section>
  </Layout>
)

export default NotFoundPage
